import type { RouteLocation, RouteLocationNormalized } from 'vue-router'

export default defineNuxtRouteMiddleware(async (to) => {
  const user = useSupabaseUser()
  const supabaseClient = useSupabaseClient()
  const userIsAdmin = computed(() => user.value?.app_metadata?.role === 'admin')

  if (
    sessionStorage.getItem('refloatDisableRedirection') === 'true'
    && to.name !== 'reset-password'
  )
    return navigateTo({ path: '/reset-password' })

  try {
    if (
      !userIsAdmin.value
      && isUserAccessingAdminProtectedRoute(to as RouteLocationNormalized)
    )
      return false
  }
  catch (error) {
    await logOutAndRedirect(supabaseClient.auth, false)
  }
})

async function logOutAndRedirect(
  supabaseAuthClient: ReturnType<typeof useSupabaseClient>['auth'],
  redirect: boolean = true,
) {
  try {
    await supabaseAuthClient.signOut()
  }
  catch (error) {
  }

  if (redirect)
    return await navigateTo('/login')
}

function isUserAccessingAdminProtectedRoute(
  route: RouteLocation,
) {
  return route.path.startsWith('/admin')
}
